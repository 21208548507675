import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useRef } from "react";
import useRequestID from "../../../hooks/useRequestID";
import {
  isAuthenticatedState,
  profileTypeState,
  supplierState,
  userEmailVerifiedState,
  userInitializedState,
} from "../../../jotai/user";
import {
  CardContainer,
  CardContainerVariant,
  PageSection,
} from "../../../library";
import type { SupplierProfileData } from "../../../pages/Supplier";
import AccountVerificationBanner from "../../../shared/AccountVerificationBanner";
import AnchorRefLinks, {
  type AnchorRefLink,
} from "../../../shared/AnchorRefLinks";
import RecommendationCarousel, {
  RecommendationTypes,
} from "../../../shared/RecommendationCarousel/RecommendationCarousel";
import { PageType, ProfileType } from "../../../utils/enums";
import { supplierHasFeature } from "../../../utils/featureManagement";
import {
  referredFromSearchEngine,
  trackClickSupplierRecommendation,
  trackViewSupplierRecommendations,
} from "../../../utils/tracking";
import ScannableSupplierAbout from "./ScannableSupplierAbout";
import { PAGE_WIDTH_CLASS } from "./constants";

interface ScannableSupplierProfileProps {
  supplier: SupplierProfileData;
  hasClaimedSupplier: boolean;
  ownsSupplier: boolean;
  renderSupplierCTA: () => JSX.Element;
  trackClickContactSupplier: () => void;
}

export default function ScannableSupplierProfile({
  supplier,
  hasClaimedSupplier,
  ownsSupplier,
  renderSupplierCTA,
  trackClickContactSupplier,
}: ScannableSupplierProfileProps) {
  const {
    id,
    name,
    // claimed,
    // contracts,
    // contracts_count,
    // supplier_contact: { website },
    // supplier_phone_contacts,
    // has_confirmed_contact,
    handle,
    supplier_compliance,
    service_area_data,
    logo_url,
    // has_products,
    // pro_qualifications,
    sat_url,
    // saved_project_id,
  } = supplier;
  // const [userState, setUserState] = useAtom(userStateState);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const profileType = useAtomValue(profileTypeState);
  const isInitialized = useAtomValue(userInitializedState);
  const isEmailVerified = useAtomValue(userEmailVerifiedState);
  const isUnverified = hasClaimedSupplier && !isEmailVerified;
  const isPending = hasClaimedSupplier && isEmailVerified && !ownsSupplier;
  const userSupplier = useAtomValue(supplierState);
  // const requireLogin = useLoginWall();
  // const query = getParam("query");
  const showProductListAndCta =
    userSupplier.handle === handle || profileType !== ProfileType.SUPPLIER;

  const contractsRef = useRef<HTMLDivElement>(null);
  const detailsRef = useRef<HTMLDivElement>(null);
  const productsRef = useRef<HTMLDivElement>(null);
  const simliarSuppliersRef = useRef<HTMLDivElement>(null);

  const requestID = useRequestID();

  const anchorLinkTrackingParams = {
    supplierId: supplier.id,
    supplierHandle: supplier.handle,
    pageType: PageType.SUPPLIER,
  };

  const links: AnchorRefLink[] = [
    {
      pageId: supplier.id.toString(),
      name: "Contracts",
      ref: contractsRef,
      className: "analytics-supplier-contracts-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
    },
    {
      pageId: supplier.id.toString(),
      name: "Supplier details",
      ref: detailsRef,
      className: "analytics-supplier-details-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
    },
    {
      pageId: supplier.id.toString(),
      name: "Products",
      ref: productsRef,
      className: "analytics-supplier-products-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
    },
    {
      pageId: supplier.id.toString(),
      name: "Similar suppliers",
      ref: simliarSuppliersRef,
      className: "analytics-supplier-similar-link",
      scrollOptions: { behavior: "smooth" },
      trackingParams: anchorLinkTrackingParams,
      requireLogin: false,
    },
  ];

  return (
    <PageSection className="flex flex-col mt-12 mb-24">
      {isInitialized &&
        (isUnverified || isPending) &&
        !referredFromSearchEngine() && (
          <div className="mb-8 w-full">
            <AccountVerificationBanner />
          </div>
        )}
      <div
        className={clsx(
          "w-full grid grid-cols-12 gap-x-10 gap-y-8 md:my-0 bg-white",
          PAGE_WIDTH_CLASS
        )}
      >
        <div className="col-span-full lg:col-span-8 flex flex-col gap-8">
          <CardContainer
            variant={CardContainerVariant.SECONDARY}
            className="p-6 rounded-6 h-fit"
          >
            <ScannableSupplierAbout
              title={name}
              supplierId={id}
              supplierName={name}
              supplierHandle={handle}
              supplierActiveAgreements={supplier.active_agreements}
              supplierCompliance={supplier_compliance}
              supplierServiceArea={service_area_data}
              supplierAbout={supplier.about}
              supplierSummary={supplier.summary}
              logoUrl={logo_url}
              satUrl={sat_url}
              showSaveToProject={
                isAuthenticated && profileType === ProfileType.BUYER
              }
              trackClickContactSupplier={trackClickContactSupplier}
            />
          </CardContainer>
          <AnchorRefLinks links={links} />
        </div>
        {showProductListAndCta && (
          <div className="block col-span-full lg:hidden empty:hidden">
            {renderSupplierCTA()}
          </div>
        )}
        {showProductListAndCta && (
          <div className="hidden lg:block col-span-full lg:col-span-4 empty:hidden">
            <div className="sticky top-24">{renderSupplierCTA()}</div>
          </div>
        )}
        <RecommendationCarousel
          className="col-span-full pt-10 border-solid border-cp-white-200 border-t"
          type={RecommendationTypes.supplierToSupplier}
          id={handle}
          onRender={(suppliers) => {
            if (suppliers?.length) {
              trackViewSupplierRecommendations({
                supplierId: supplier.id,
                supplierName: supplier.name,
                supplierHandle: supplier.handle,
                requestId: requestID,
                numRecommendations: suppliers.length,
                supplierRecommendations: suppliers
                  .map((s) => s.supplier.handle)
                  .join(","),
                proSupplierRecommendations: suppliers
                  .filter((s) =>
                    supplierHasFeature(
                      s.supplierAgreement.activeAgreements,
                      "analyticsTrackIsPro"
                    )
                  )
                  .map((s) => s.supplier.handle)
                  .join(","),
              });
            }
          }}
          onClick={({ supplier: recSupplier }) => {
            trackClickSupplierRecommendation({
              supplierId: supplier.id,
              supplierName: supplier.name,
              supplierHandle: supplier.handle,
              requestId: requestID,
              recommendedSupplierId: recSupplier.supplier.id,
              recommendedSupplierHandle: recSupplier.supplier.handle,
              recommendedProSupplier: supplierHasFeature(
                recSupplier.supplierAgreement.activeAgreements,
                "analyticsTrackIsPro"
              ),
            });
          }}
        />
      </div>
    </PageSection>
  );
}
